import Vue from "vue";
import { PushNotifications } from "@capacitor/push-notifications";
import { NotificationService } from "@services";
import { DateTime } from "luxon";

import router from "@/router";
const state = {
  notifications: [],
  fcmToken: "",
};
const getters = {
  notifications: (state) => {
    return state.notifications;
  },
  fcmToken: (state) => {
    return state.fcmToken;
  },
};
const actions = {
  async getPushNotifications({ commit }) {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    const notifications = notificationList?.notifications;
    commit("notifications", notifications);
  },
  async removePushNotifications({ commit }) {
    await PushNotifications.removeAllDeliveredNotifications();
    commit("notifications", []);
  },
  addPushNotification({ commit }, n) {
    commit("addNotification", n);
  },
  removePushNotification({ commit }, notificationId) {
    commit("removeNotification", notificationId);
  },
  fcmToken({ commit }, t) {
    commit("fcmToken", t);
  },
  listenPushNotifications({ commit }) {
    PushNotifications.addListener("registration", (token) => {
      let pushReg = {};
      pushReg.deviceId = Vue.prototype.$deviceId;
      pushReg.pushRegistrationToken = token.value;
      pushReg.pushRegistrationExpiresOn = DateTime.utc().plus({ months: 2 }).toISO(); //https://firebase.google.com/docs/cloud-messaging/manage-tokens
      console.log(pushReg);
      NotificationService.registerFCMToken(pushReg);
      commit("fcmToken", token.value);
    });

    PushNotifications.addListener("registrationError", (error) => {
      commit("fcmToken", error.err);
    });

    PushNotifications.addListener("pushNotificationReceived", (notification) => {
      commit("addNotification", notification);
    });

    PushNotifications.addListener("pushNotificationActionPerformed", (action) => {
      if (action.actionId == "tap") {
        router.push({ name: "messages" });
      }
    });
  },
  async requestPushNotifications({ dispatch }) {
    const result = await PushNotifications.requestPermissions();
    if (result.receive === "granted") {
      PushNotifications.register();
    }
    dispatch("listenPushNotifications");
  },
  unregisterFCMToken() {
    NotificationService.unregisterFCMToken();
  },
};
const mutations = {
  notifications(state, value) {
    state.notifications = value;
  },
  addNotification(state, value) {
    state.notifications.push({ ...value });
  },
  removeNotification(state, notificationId) {
    state.notifications = state.notifications.filter((obj) => {
      return obj.id != notificationId;
    });
  },
  fcmToken(state, value) {
    state.fcmToken = value;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
