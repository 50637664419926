const state = {
  drawer: false,
};
const getters = {
  // send state to a component
  navDrawerState: (state) => {
    return state.drawer;
  },
};
const mutations = {
  navDrawer: (state, command) => {
    command === "open" ? (state.drawer = true) : (state.drawer = false);
  },
};
const actions = {
  // modify state aschronously
  navDrawer: ({ commit }, command) => {
    commit("navDrawer", command);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
