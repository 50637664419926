import Vue from "vue";
import { TokenService, UserService } from "@services";
import { i18n } from "@/plugins/i18n";
import store from "@/store";
import tracer from "@/diagnostics/tracer.js";

let block = false;
let retry = [];
let base;
const headers = new Headers();

export const init = () => {
  base = store.state.api.apiURL ? store.state.api.apiURL : config.VUE_APP_ROOT_API || process.env.VUE_APP_ROOT_API;
  const apiKey = store.state.api.apiKey
    ? store.state.api.apiKey
    : config.VUE_APP_API_ANONYMOUSKEY || process.env.VUE_APP_API_ANONYMOUSKEY;
  headers.set("X-API-KEY", apiKey);
  headers.set("Accept-Language", store.state.settings.lang);
  headers.set("Content-Type", "application/json");
  if (TokenService.getToken()) {
    setAuthHeader();
  }
};

export const setAuthHeader = () => {
  headers.set("Authorization", `Bearer ${TokenService.getToken()}`);
};

export const removeAuthHeader = () => {
  headers.delete("Authorization");
};

export const get = (urlString, params) => request(urlString, params, "GET");

export const post = (urlString, data, silent = false) => request(urlString, data, "POST", silent);

export const patch = (urlString, data) => request(urlString, data, "PATCH");

export const put = (urlString, data) => request(urlString, data, "PUT");

export const del = (urlString, data) => request(urlString, data, "DELETE");

const request = (urlString, params, method, silent) => {
  if (!base) {
    init();
  }
  // put all requests in pending Promise until token is refreshed
  if (block && urlString !== "/public/auth/refresh-token") {
    tracer.info("Queued API Call until Token Refresh", {
      urlString: urlString,
      params: tracer.sanitize(params),
      method: method,
    });

    return new Promise((resolve) => {
      retry.push(() => resolve(request(urlString, params, method, silent)));
    });
  }

  const url = new URL(urlString, base);
  const options = { method, headers };
  if (method === "GET") {
    url.search = new URLSearchParams(params);
  } else {
    options.body = JSON.stringify(params);
  }

  return fetch(url, options)
    .then((r) => {
      // parse and wrap the response in a new simplified Promise
      return new Promise((resolve, reject) =>
        r.json().then((json) => {
          if (r.ok) {
            tracer.info("[API] OK | " + url, {
              params: tracer.sanitize(params),
              method: method,
              response: json,
            });

            return resolve({ data: json.data, meta: json.meta });
          }

          if (r.status === 401 && !r.url.includes("authenticate")) {
            tracer.error("[API] Received 401, starting token refresh handler | " + url, {
              params: tracer.sanitize(params),
              method: method,
              response: json,
            });

            if (!block) {
              block = true;
              UserService.refreshToken().then(() => {
                block = false;
                retry.forEach((cb) => cb());
                retry = [];
              });
            }
            // put all requests in pending Promise until token is refreshed
            retry.push(() => resolve(request(urlString, params, method)));
          } else {
            tracer.error("[API] Error | " + url, {
              params: tracer.sanitize(params),
              method: method,
              response: json,
            });

            let firstError = json.errors[0];
            let errorMsg = i18n.t("err_generic");
            if (firstError && firstError.code && i18n.te(firstError?.code)) {
              errorMsg = i18n.t(firstError?.code);
            } else if (firstError && firstError.englishTranslation) {
              errorMsg = firstError.englishTranslation;
            }

            if (!silent) {
              Vue.prototype.$snackbar.showMessage({ content: errorMsg, color: "error", timeout: 3000 });
            }

            return reject(json.errors);
          }
        }),
      );
    })
    .catch((error) => {
      if (Object.keys(error).length === 0) {
        tracer.error("[API] Unreachable | " + url, {
          params: tracer.sanitize(params),
          method: method,
        });
      } else {
        throw error;
      }
    });
};
