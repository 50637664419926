import { DateTime } from "luxon";

var DB_NAME = "ranesdb";
const DB_VERSION = 13;
let DB;

const S3_EXPIRATION_MINUTES = 10080; // 1 week

//for additional stores... need to update the version above and add stores to onupgradeneeded
export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      // let userStr = store.state['auth'] ? store.state['auth'].user : "";
      // let user = JSON.parse(userStr);
      // dbName = "ranes" + user.id;
      // console.log(dbName)
      try {
        if (DB) {
          return resolve(DB);
        }
        let request = window.indexedDB.open(DB_NAME, DB_VERSION);
        request.onerror = (e) => {
          console.log("Error opening db", e);
          reject("Error");
        };
        request.onsuccess = (e) => {
          DB = e.target.result;
          resolve(DB);
        };
        request.onupgradeneeded = (e) => {
          console.log("onupgradeneeded");
          console.log("Old:" + e.oldVersion);
          console.log("Current:" + DB_VERSION);
          let db = e.target.result;
          if (e.oldVersion < 1) {
            // do initial schema creation
            db.createObjectStore("user", { keyPath: "id" });
            db.createObjectStore("clockIns", {
              autoIncrement: true,
              keyPath: "id",
            });
            db.createObjectStore("timeClocks", {
              autoIncrement: true,
              keyPath: "id",
            });
            db.createObjectStore("clockInJobs", {
              autoIncrement: true,
              keyPath: "id",
            });
            db.createObjectStore("clockInCategories", {
              autoIncrement: true,
              keyPath: "id",
            });
            db.createObjectStore("clockInCostCodes", {
              autoIncrement: true,
              keyPath: "id",
            });
            db.createObjectStore("clockInOutReasons", {
              autoIncrement: true,
              keyPath: "id",
            });
            db.createObjectStore("s3Cache", {
              autoIncrement: true,
              keyPath: "key",
            });
          }
          //could do a version specific approach, but... we'll try and just check the stores and create if needed.
          if (e.oldVersion < 8) {
            // do 1->2 upgrade
            db.deleteObjectStore("user"); // migrating data would be better
          }
          // if (e.oldVersion < 3) {
          //     // do 2->3 upgrade
          //     rq.transaction.objectStore('better_users').createIndex('index2', ...);
          // }
          // if (e.oldVersion < 4) {
          //     // do 3->4 upgrade
          //     db.createObjectStore('messages', ...);
          // }
          if (!db.objectStoreNames.contains("user")) {
            db.createObjectStore("user", { keyPath: "id" });
          }
          if (!db.objectStoreNames.contains("clockIns")) {
            db.createObjectStore("clockIns", {
              autoIncrement: true,
              keyPath: "id",
            });
          }
          if (!db.objectStoreNames.contains("timeClocks")) {
            db.createObjectStore("timeClocks", {
              autoIncrement: true,
              keyPath: "id",
            });
          }
          if (!db.objectStoreNames.contains("clockInJobs")) {
            db.createObjectStore("clockInJobs", {
              autoIncrement: true,
              keyPath: "id",
            });
          }
          if (!db.objectStoreNames.contains("clockInCategories")) {
            db.createObjectStore("clockInCategories", {
              autoIncrement: true,
              keyPath: "id",
            });
          }
          if (!db.objectStoreNames.contains("clockInCostCodes")) {
            db.createObjectStore("clockInCostCodes", {
              autoIncrement: true,
              keyPath: "id",
            });
          }
          if (!db.objectStoreNames.contains("clockInOutReasons")) {
            db.createObjectStore("clockInOutReasons", {
              autoIncrement: true,
              keyPath: "id",
            });
          }

          if (!db.objectStoreNames.contains("s3cache")) {
            let s3cacheStore = db.createObjectStore("s3cache", {
              autoIncrement: false,
              keyPath: "key",
            });

            s3cacheStore.createIndex("timestamp", "timestamp", {
              unique: false,
            });
          }
        };
      } catch (error) {
        reject(error); //hack fix for maybe an indexeddb error... https://github.com/jensarps/IDBWrapper/issues/80
      }
    });
  },
  async deleteRanesDatabase(confirm = false) {
    //let db = await this.getDb();
    //var self = this;
    // const dbs = await window.indexedDB.databases()
    // dbs.forEach(db => { window.indexedDB.deleteDatabase(db.name) })
    // console.log('deleted dbs')
    if (confirm) {
      let db = await this.getDb();
      db.close();
      //var req = db.deleteDatabase(DB_NAME);
      var req = window.indexedDB.deleteDatabase(DB_NAME);
      req.onsuccess = function () {
        console.log("Deleted database successfully");
      };
      req.onerror = function () {
        console.log("Couldn't delete database");
      };
      req.onblocked = function () {
        //self.doDelete();
        console.log("Couldn't delete database due to the operation being blocked");
        db.close();
        //self.deleteRanesDatabase();
      };
      return req;
    }
    return;
  },
  async deleteAllStores() {
    let db = await this.getDb();
    db.transaction(["user"], "readwrite").objectStore("user").clear();
    db.transaction(["clockIns"], "readwrite").objectStore("clockIns").clear();
    db.transaction(["timeClocks"], "readwrite").objectStore("timeClocks").clear();
    db.transaction(["clockInJobs"], "readwrite").objectStore("clockInJobs").clear();
    db.transaction(["clockInCategories"], "readwrite").objectStore("clockInCategories").clear();
    db.transaction(["clockInCostCodes"], "readwrite").objectStore("clockInCostCodes").clear();
    db.transaction(["clockInOutReasons"], "readwrite").objectStore("clockInOutReasons").clear();
    db.transaction(["s3cache"], "readwrite").objectStore("s3cache").clear();
  },
  async deleteTimeClock(timeClockId) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["timeClocks"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("timeClocks");
      store.delete(timeClockId);
    });
  },
  async deleteTimeClocks() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["timeClocks"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("timeClocks");
      store.clear();
    });
  },
  async deleteOldTimeClocks() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["timeClocks"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("timeClocks");
      let count = 0;
      store.openCursor(null, "prev").onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (count > 160) {
            this.deleteTimeClock(cursor.value.id);
          }
          cursor.continue();
          count++;
        }
      };
    });
  },
  async getActiveTimeClock(direction = "prev") {
    //direction: "next", "nextunique", "prev", and "prevunique"
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["timeClocks"], "readonly");
      trans.oncomplete = () => {
        resolve(timeClock);
      };
      let store = trans.objectStore("timeClocks");
      let timeClock;
      store.openCursor(null, direction).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (!cursor.value.clockEnd) {
            timeClock = cursor.value;
          }
          cursor.continue();
        }
      };
    });
  },
  async getNewestTimeClock(direction = "prev") {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["timeClocks"], "readonly");
      trans.oncomplete = () => {
        resolve(timeClock);
      };
      let store = trans.objectStore("timeClocks");
      let timeClock;
      store.openCursor(null, direction).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          timeClock = cursor.value;
        }
      };
    });
  },
  async getTimeClock(timeClockId) {
    if (!timeClockId) {
      return;
    }
    timeClockId = parseInt(timeClockId);
    let db = await this.getDb();
    return new Promise((resolve) => {
      let timeClock;
      let trans = db.transaction(["timeClocks"], "readonly");
      trans.oncomplete = () => {
        resolve(timeClock);
      };
      let store = trans.objectStore("timeClocks");
      store.get(timeClockId).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          timeClock = cursor;
        }
      };
    });
  },
  async getTimeClocks() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["timeClocks"], "readonly");
      trans.oncomplete = () => {
        resolve(timeClocks);
      };

      let store = trans.objectStore("timeClocks");
      let timeClocks = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          timeClocks.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
  async getActiveClockIn(direction = "prevunique") {
    //direction: "next", "nextunique", "prev", and "prevunique"
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockIns"], "readonly");
      trans.oncomplete = () => {
        resolve(clockIn);
      };
      let store = trans.objectStore("clockIns");
      let clockIn;
      store.openCursor(null, direction).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (!cursor.value.clockEnd) {
            clockIn = cursor.value;
          }
          cursor.continue();
        }
      };
    });
  },
  async getClockIn(clockInId) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockIns"], "readonly");
      trans.oncomplete = () => {
        resolve(clockIn);
      };
      let store = trans.objectStore("clockIns");
      let clockIn = {};
      store.get(clockInId).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          clockIn = cursor;
        }
      };
    });
  },
  async getClockIns() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockIns"], "readonly");
      trans.oncomplete = () => {
        resolve(timeClocks);
      };

      let store = trans.objectStore("clockIns");
      let timeClocks = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          timeClocks.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
  async deleteClockIn(ClockIn) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockIns"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("clockIns");
      store.delete(ClockIn.id);
    });
  },
  async deleteClockIns() {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockIns"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("clockIns");
      store.clear();
    });
  },
  async saveClockIn(clockIn) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockIns"], "readwrite");
      trans.oncomplete = () => {
        resolve(clockIn);
      };
      let store = trans.objectStore("clockIns");
      store.put(clockIn);
    });
  },
  async saveTimeClock(timeClock) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["timeClocks"], "readwrite");
      trans.oncomplete = () => {
        resolve(timeClock);
      };
      let store = trans.objectStore("timeClocks");
      store.put(timeClock);
    });
  },
  async saveClockInJobs(jobs) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInJobs"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("clockInJobs");
      store.put(jobs);
    });
  },
  async getClockInJobs(direction = "prev") {
    //direction: "next", "nextunique", "prev", and "prevunique"
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInJobs"], "readonly");
      trans.oncomplete = () => {
        resolve(clockInJobs);
      };
      let store = trans.objectStore("clockInJobs");
      let clockInJobs = {};
      store.openCursor(null, direction).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          clockInJobs = cursor.value;
        }
      };
    });
  },
  async saveClockInCategories(categories) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInCategories"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("clockInCategories");
      store.put(categories);
    });
  },
  async getClockInCategories(direction = "next") {
    //direction: "next", "nextunique", "prev", and "prevunique"
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInCategories"], "readonly");
      trans.oncomplete = () => {
        resolve(clockInCategories);
      };
      let store = trans.objectStore("clockInCategories");
      let clockInCategories = {};
      store.openCursor(null, direction).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          clockInCategories = cursor.value;
        }
      };
    });
  },
  async saveClockInCostCodes(costCodes) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInCostCodes"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("clockInCostCodes");
      store.put(costCodes);
    });
  },
  async getClockInCostCodes(direction = "next") {
    //direction: "next", "nextunique", "prev", and "prevunique"
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInCostCodes"], "readonly");
      trans.oncomplete = () => {
        resolve(clockInCostCodes);
      };
      let store = trans.objectStore("clockInCostCodes");
      let clockInCostCodes = {};
      store.openCursor(null, direction).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          clockInCostCodes = cursor.value;
        }
      };
    });
  },
  async saveJobCostCodes(costCodes) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInCostCodes"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("clockInCostCodes");
      store.put(costCodes);
    });
  },
  async getJobCostCodes(jobId) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInCostCodes"], "readonly");
      trans.oncomplete = () => {
        resolve(jobCostCodes);
      };
      let store = trans.objectStore("clockInCostCodes");
      let jobCostCodes = {};
      store.get(jobId).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          jobCostCodes = cursor;
        }
      };
    });
  },
  async saveClockInOutReasons(reasons) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInOutReasons"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("clockInOutReasons");
      store.put(reasons);
    });
  },
  async getClockInOutReasons(direction = "next") {
    //direction: "next", "nextunique", "prev", and "prevunique"
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["clockInOutReasons"], "readonly");
      trans.oncomplete = () => {
        resolve(clockInOutReasons);
      };
      let store = trans.objectStore("clockInOutReasons");
      let clockInOutReasons = {};
      store.openCursor(null, direction).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          clockInOutReasons = cursor.value;
        }
      };
    });
  },
  async getProjects(direction = "next") {
    //direction: "next", "nextunique", "prev", and "prevunique"
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["timeClocks"], "readonly");
      trans.oncomplete = () => {
        resolve(timeClock);
      };
      let store = trans.objectStore("timeClocks");
      let timeClock;
      store.openCursor(null, direction).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          timeClock = cursor.value;
        }
      };
    });
  },
  async saveUser(user) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["user"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };
      let store = trans.objectStore("user");
      let userId = parseInt(user.userId);
      store.put({ id: userId, value: user }); // OK
    });
  },
  async getUser(direction = "prev") {
    //direction: "next", "nextunique", "prev", and "prevunique"
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["user"], "readonly");
      trans.oncomplete = () => {
        resolve(user);
      };
      let store = trans.objectStore("user");
      let user = {};
      store.openCursor(null, direction).onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          user = cursor.value;
        }
      };
    });
  },

  /**
   * Retreives an S3 Item from the IDB if it matches
   * @param {string} key The GUID key of the S3 Item
   * @returns {object|null} The S3 Item object or null if there is no matching unexpired item
   */
  async getS3Item(key) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let s3Item = null;

      let trans = db.transaction(["s3cache"], "readwrite");
      trans.oncomplete = () => {
        resolve(s3Item);
      };

      let getRequest = trans.objectStore("s3cache").get(key);

      getRequest.onsuccess = () => {
        s3Item = getRequest.result;

        if (
          s3Item?.timestamp == null ||
          s3Item.timestamp <= DateTime.now().toUTC().minus({ minutes: S3_EXPIRATION_MINUTES }).toUnixInteger()
        ) {
          s3Item = null;

          trans.objectStore("s3cache").delete(key);
        }
      };
    });
  },

  /**
   * Stores an S3 Item in the IDB
   * @param {string} key The GUID key of the S3 Item
   * @param {string} blob The blob string to be stored
   * @returns {void}
   */
  async putS3Item(key, value) {
    if (key == null) {
      throw "Key must be provided!";
    }

    if (value == null) {
      throw "Value must be provided!";
    }

    let timestamp = DateTime.now().toUTC().toUnixInteger();

    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["s3cache"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      trans.objectStore("s3cache").put({
        key: key,
        timestamp: timestamp,
        value: value,
      });
    });
  },

  /**
   * Expire all items older than the S3_EXPIRATION_MINUTES constant
   * @returns {void}
   */
  async expireS3Items() {
    let range = IDBKeyRange.upperBound(
      DateTime.now().toUTC().minus({ minutes: S3_EXPIRATION_MINUTES }).toUnixInteger(),
    );

    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["s3cache"], "readwrite");
      trans.oncomplete = () => {
        resolve();
      };

      trans.objectStore("s3cache").index("timestamp").openCursor(range).onsuccess = (e) => {
        let cursor = e.target.result;
        if (!cursor) {
          return;
        }

        cursor.delete();
        cursor.continue();
      };
    });
  },
};
