const state = {
  image: null,
};

const getters = {
  image: (state) => state.image,
};

const actions = {
  setImage({ commit }, image) {
    commit("setImage", image);
  },
};

const mutations = {
  setImage(state, image) {
    state.image = image;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
