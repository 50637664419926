import idb from "@/api/idb";
import { mapActions } from "vuex";
import Helpers from "@/mixins/helpers";

export default {
  computed: {},
  mixins: [Helpers],
  data() {
    return {};
  },
  watch: {
    $online: function () {
      if (this.$online) {
        const syncs = [];
        idb
          .getClockIns()
          .then((clockIns) => {
            clockIns.forEach((clockIn) => {
              //cases
              //clockStart && !timeClockId && !clockEnd : offline clockin no clockout
              //clockStart && !timeClockId && clockStart && clockEnd : offline clockin and clockout
              //clockStart && timeClockId && clockEnd : online clockin and offline clockout
              if (clockIn.timeClockId && clockIn.clockEnd) {
                //only if they did clockout
                syncs.push(this.clockOut(clockIn));
              } else if (!clockIn.timeClockId && !clockIn.clockEnd) {
                syncs.push(this.clockIn(clockIn)); //clockin is only local... clock in
              } else if (!clockIn.timeClockId) {
                //clock in and out
                syncs.push(this.syncTimeClock(clockIn));
              }
            });
            Promise.all(syncs)
              .then((results) => {
                if (results.length > 0) {
                  this.$snackbar.showMessage({
                    content: this.$t("c_snackbar.t_syncing_in_progress"),
                    color: "warning",
                    timeout: "3000",
                  });
                }
                this.getTimeClocks();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        console.log("you are offline");
      }
    },
  },
  methods: {
    ...mapActions("timeClock", ["doClockOut", "doClockIn", "getTimeClocks", "saveTimeClock", "saveTimeClockNote"]),
    async syncTimeClock(clockIn) {
      clockIn.id = null;
      //todo - clockEndNote save to user/timeclock/note with incidentReport.
      return this.saveTimeClock(clockIn).then((r) => {
        if (clockIn.incidentReport == "true") {
          let params = {};
          (params.timeclockId = r.data?.timeclockId),
            (params.fitForDuty = null),
            (params.incidentReport = true),
            (params.note = clockIn.clockEndNote);
          this.saveTimeClockNote(params);
        }
        return r.data;
      });
    },
    async clockIn(clockIn) {
      return this.doClockIn(clockIn);
    },
    async clockOut(clockIn) {
      let clockOut = {};
      clockOut.id = clockIn.timeClockId;
      clockOut.clockEndLatLng = clockIn.clockEndLatLng;
      clockOut.clockEndLatLngMethod = this.getDeviceInfo();
      clockOut.clockEndReasonId = clockIn.clockEndReason ? clockIn.clockEndReason.id : null;
      clockOut.fitForDuty = null;
      clockOut.incidentReport = clockIn.incidentReport;
      clockOut.timeClockNote = clockIn.clockEndNote;
      return await this.doClockOut(clockOut);
    },
  },
};
