import { get, post, patch, setAuthHeader } from "./api.service";
import TokenService from "./token.service";
import store from "@/store";
import tracer from "@/diagnostics/tracer.js";

const UserService = {
  async login(email, password) {
    const params = {
      email: email,
      password: password,
      adminPortal: false,
    };
    const r = await post("/public/auth/authenticate", params);
    if (r.data?.accessToken && r.data?.refreshToken) {
      const accessToken = r.data.accessToken.token ? r.data.accessToken.token : r.data.accessToken;
      TokenService.saveToken(accessToken);
      const refreshToken = r.data.refreshToken.token ? r.data.refreshToken.token : r.data.refreshToken;
      TokenService.saveRefreshToken(refreshToken);
      setAuthHeader();
      return "ok";
    } else {
      return r;
    }
  },
  forgotPassword(params) {
    return post("/public/auth/forgot-password", params);
  },
  resetPassword(params) {
    return post("/public/auth/reset-password", params);
  },
  changePassword(params) {
    return post("/user/auth/change-password", params);
  },
  refreshToken() {
    return fetch(new URL("/public/auth/refresh-token", store.state.api.apiURL), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": store.state.api.apiKey,
      },
      body: JSON.stringify({ token: TokenService.getRefreshToken() }),
    })
      .then((r) => {
        if (!r.ok) {
          tracer.error("Fetch fail for refresh-token: Not response.ok | /public/auth/refresh-token", {
            params: JSON.stringify({ token: TokenService.getRefreshToken() }),
            method: "POST",
            response: JSON.stringify(r),
          });
          store.dispatch("auth/restart", null, { root: true });
        }
        return r.json().then((json) => {
          //Api comes back with with the token as a property on the accessToken and refreshToken objects for this case. This bug was forcing re-authenticating.
          let accessToken = json.data.accessToken.token ? json.data.accessToken.token : json.data.accessToken;
          let refreshToken = json.data.refreshToken.token ? json.data.refreshToken.token : json.data.refreshToken;

          TokenService.saveToken(accessToken);
          TokenService.saveRefreshToken(refreshToken);
          setAuthHeader();
          return Promise.resolve();
        });
      })
      .catch((e) => {
        tracer.info("Error /public/auth/refresh-token: " + e);
        store.dispatch("auth/logout", null, { root: true });
      });
  },
  registerUser(params) {
    return post("/public/invitation", params);
  },
  getInvitation(params) {
    return get("/public/invitation", params);
  },
  getUser() {
    return get("user/user/me");
  },
  updateUserProfile(params) {
    return patch("/user/user/me", params);
  },
  linkProfileImage(params) {
    return post("/user/user/profileimage/link", params);
  },
  unlinkProfileImage() {
    return post("/user/user/profileimage/unlink");
  },
};

export { UserService };
