import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import Filters from "@/filters";
import VueMeta from "vue-meta";
import Vue2Filters from "vue2-filters";
import VueMask from "v-mask";
import VueOnlineProp from "vue-online-prop";
import SnackBar from "@/plugins/snackbar";
import VueGeolocationApi from "vue-geolocation-api";
import { i18n } from "@/plugins/i18n";
import { init } from "@services";
import { Device } from "@capacitor/device";
import { Network } from "@capacitor/network";

Vue.use(Vue2Filters);
Vue.use(Filters);
Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.use(VueMask);
Vue.use(SnackBar, { store });
Vue.use(VueGeolocationApi);
Vue.use(VueOnlineProp);
Device.getInfo().then((a) => {
  Vue.prototype.$deviceInfo = a;
  Vue.prototype.$platform = a.platform;
  if (a.platform !== "web") {
    Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status);
    });
    Network.getNetworkStatus().then((n) => {
      console.log("Network: ", n);
      Vue.prototype.$network = n.connected;
      Vue.prototype.$online = n.connected;
    });
  }
});
Device.getId().then((a) => {
  Vue.prototype.$deviceId = a.uuid;
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  data: {
    nav: ["dashboard", "timeclock", "forms", "messages", "documents"],
  },
  async beforeCreate() {
    const apiURL = config.VUE_APP_ROOT_API || process.env.VUE_APP_ROOT_API;
    const apiKey = config.VUE_APP_API_ANONYMOUSKEY || process.env.VUE_APP_API_ANONYMOUSKEY;

    const version = process.env.VUE_APP_VERSION; // baked by build system

    store.commit("api/apiURL", apiURL);
    store.commit("api/apiKey", apiKey);
    store.commit("settings/version", version);

    init();
  },
}).$mount("#app");
