const state = {
  geoLocationCoords: null,
};

const getters = {
  geoLocationCoords: (state) => {
    return state;
  },
};

const actions = {
  async setGeoLocation({ commit }, location) {
    commit("setGeoLocation", location);
  },
  removeGeo({ commit }) {
    commit("geoLocationCoords", null);
  },
};

const mutations = {
  setGeoLocation(state, location) {
    state.geoLocationCoords = location;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
