import { MessageService } from "@services";
import Vue from "vue";

const state = {
  lastMessageId: null,
  messages: null,
};

const getters = {
  lastMessageId: (state) => {
    return state.lastMessageId;
  },
};

const actions = {
  async getMessage({ state }, messageLogId) {
    if (!Vue.prototype.$online) {
      let m = {};
      m.data = state.messages.data.find((m) => m.id == messageLogId);
      return m;
    } else {
      return await MessageService.getMessage(messageLogId);
    }
  },
  async getMessages({ commit, state }, params) {
    //store highest message Id... if message id is greater then set new flag
    if (!Vue.prototype.$online) {
      return state.messages;
    } else {
      let m = await MessageService.getMessages(params);
      let messages = m.data;
      if (messages && messages.length) {
        let maxId = messages.reduce((messages, compare) => (messages.id > compare.id ? messages : compare)).id;
        if (maxId > state.lastMessageId) {
          commit("lastMessageId", maxId);
        }
      }
      commit("messages", m);
      return m;
    }
  },
  async getMessageLatestCompany() {
    return await MessageService.getMessageLatestCompany();
  },
  async getMessageLatestSystem() {
    return await MessageService.getMessageLatestSystem();
  },
  removeMessages({ commit }) {
    commit("messages", null);
  },
};

const mutations = {
  lastMessageId(state, value) {
    state.lastMessageId = value;
  },
  messages(state, value) {
    state.messages = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
