export const DEFAULT_LANGUAGE = "en-US";
export const FALLBACK_LANGUAGE = "en-US";
export const SUPPORTED_LANGUAGES = ["en-US", "es-MX", "pt-BR", "fr-CA", "de-DE", "da-DK", "ar-EG", "kl-GL", "zh-CN"];
export const SUPPORTED_LANGUAGES_OBJ = [
  { code: "en-US", name: "English" },
  { code: "es-MX", name: "Español (Spanish)" },
  { code: "pt-BR", name: "Português (Portuguese)" },
  { code: "fr-CA", name: "Français (French)" },
  { code: "de-DE", name: "Deutsch (German)" },
  { code: "da-DK", name: "Dansk (Danish)" },
  { code: "ar-EG", name: "العربية (Arabic)" },
  { code: "kl-GL", name: "Kalaallisut (Greenlandic)" },
  { code: "zh-CN", name: "简体中文 (Simplified Chinese)" },
  // { code: "ja-JP", name: "Template" },
];
export const NUMBER_FORMATS = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
    },
  },
  "pt-BR": {
    currency: {
      style: "currency",
      currency: "€",
      currencyDisplay: "symbol",
    },
  },
  "ja-JP": {
    currency: {
      style: "currency",
      currency: "¥",
      currencyDisplay: "symbol",
    },
  },
};
