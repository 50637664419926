import { get, post } from "./api.service";

const DocumentService = {
  getDocument(documentId) {
    let params = { documentId: documentId };
    return get("/user/document", params);
  },
  getDocuments(params) {
    return get("/user/document/search", params);
  },
  getDocumentAsset() {
    return get("/user/document/asset");
  },
  getDocumentAssets(documentId) {
    let params = { documentId: documentId };
    return get("/user/document/asset/search", params);
  },
  downloadAsset(params) {
    return post("/user/document/asset/download", params);
  },
  //params: id (userIntegrationId)
  getDocumentUserIntegration(id) {
    let params = { id: id };
    return get("/user/integration/user", params);
  },
  //params: orgId, email
  getDocumentsPaperOs(params) {
    return get("/user/paper-os/documents", params);
  },
  //params: orgId, recId
  getRecordPaperOs(params) {
    return get("/user/paper-os/record", params);
  },
};

export { DocumentService };
