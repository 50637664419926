import { get, post, del } from "./api.service";

const TrainingService = {
  getCourse(courseId) {
    let params = { courseId: courseId };
    return get("/user/training/course", params);
  },
  getCourseAsset(courseId) {
    let params = { courseId };
    return get("/user/training/course/asset", params);
  },
  getCourseAssets(params) {
    return get("/user/training/course/asset/search", params);
  },
  getCourses(params) {
    return get("/user/training/course/search", params);
  },
  getCourseCredit(courseId) {
    let params = { courseId };
    return get("/user/training/course/credit", params);
  },
  getCourseCredits(params) {
    return get("/user/training/credit/search", params);
  },
  getCourseCreditTopic(creditTopicId) {
    let params = { creditTopicId };
    return get("/user/training/credit", params);
  },
  getCourseInstructor(courseId) {
    let params = { courseId };
    return get("/user/training/course/instructor", params);
  },
  createUserDownloadToken(params) {
    return post("/user/training/course/asset/token", params);
  },

  downloadAsset(params) {
    return post("/user/asset/download", params);
  },

  searchEnrollments(params) {
    return get("/user/training/enrollment/search", params);
  },

  getEnrollment(courseEnrollmentId) {
    let params = { courseEnrollmentId };
    return get("/user/training/enrollment", params);
  },

  startEnrollment(courseId) {
    let params = { courseId };
    return post("/user/training/enrollment/start", params);
  },

  submitEnrollment(courseEnrollmentId) {
    let params = { courseEnrollmentId };
    return post("/user/training/enrollment/submit", params);
  },

  cancelEnrollment(courseEnrollmentId) {
    let params = { courseEnrollmentId };
    return post("/user/training/enrollment/cancel", params);
  },

  searchWebinarRegistrations(params) {
    return get("/user/training/enrollment/webinar/registration/search", params);
  },

  getWebinar(courseWebinarId) {
    let params = { courseWebinarId };
    return get("/user/training/course/webinar", params);
  },
  searchWebinars(params) {
    return get("/user/training/course/webinar/search", params);
  },

  getRegistration(webinarRegistrationId) {
    let params = { webinarRegistrationId };
    return get("/user/training/enrollment/webinar/registration", params);
  },

  createWebinarRegistration(params) {
    return post("/user/training/enrollment/webinar/registration", params);
  },

  deleteRegistration(webinarRegistrationId) {
    let params = { webinarRegistrationId };
    return del("/user/training/enrollment/webinar/registration", params);
  },

  getEnrollments(params) {
    return get("/user/training/enrollment/search", params);
  },
  getExamAttempt(examAttemptId) {
    let params = { examAttemptId: examAttemptId };
    return get("/user/training/enrollment/exam/attempt", params);
  },
  getExamAttempts(params) {
    return get("/user/training/enrollment/exam/attempt/search", params);
  },
  getExamContent(examAttemptId) {
    let params = { examAttemptId: examAttemptId };
    return get("/user/training/enrollment/exam/content", params);
  },
  getExamQuestions(params) {
    return get("/user/training/enrollment/exam/question/search", params);
  },
  getExamAnswers(params) {
    return get("/user/training/enrollment/exam/attempt/answer/search", params);
  },
  startExam(courseEnrollmentId) {
    let params = { courseEnrollmentId: courseEnrollmentId };
    return post("/user/training/enrollment/exam/attempt/start", params);
  },
  submitExamAnswer(params) {
    return post("/user/training/enrollment/exam/attempt/answer", params);
  },
  submitExam(examAttemptId) {
    let params = { examAttemptId: examAttemptId };
    return post("/user/training/enrollment/exam/attempt/submit", params);
  },
};

export { TrainingService };
