const state = {
  apiURL: "",
  apiKey: "",
};

const mutations = {
  apiURL(state, apiURL) {
    state.apiURL = apiURL;
  },

  apiKey(state, apiKey) {
    state.apiKey = apiKey;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
