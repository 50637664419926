import { DocumentService } from "@services";
//import Vue from "vue";

const state = {
  documents: null,
};

const getters = {
  documents: (state) => {
    return { ...state.documents };
  },
};

const actions = {
  async getDocument(_, documentLogId) {
    return await DocumentService.getdocument(documentLogId);
  },
  async getDocuments({ commit }, params) {
    let d = await DocumentService.getDocuments(params);
    commit("documents", d);
    return d;
  },
  removeDocuments({ commit }) {
    commit("documents", null);
  },
};

const mutations = {
  documents(state, value) {
    state.documents = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
