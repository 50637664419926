import Vue from "vue";
import Router from "vue-router";
import { TokenService } from "@/services";
import routes from "./routes";
import tracer from "@/diagnostics/tracer";

Vue.use(Router);

const router = new Router({
  routes,
  mode: "history",
  base: process.env.BASE_URL || "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.public);
  const isAuthenticated = !!TokenService.getToken();

  if (!isPublic && !isAuthenticated) {
    tracer.info("[VUE] Navigation | " + to?.fullPath + " | Not authenticated, redirecting to Login");

    return next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else if (isAuthenticated && to.fullPath === "/login") {
    tracer.info("[VUE] Navigation | " + to?.fullPath + " | Already authenticated, redirecting to Home");

    return next("/");
  }

  tracer.info("[VUE] Navigation | " + to?.fullPath);

  next();
});

export default router;
