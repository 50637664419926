import { get, post, put, del } from "./api.service";

const FormService = {
  getFormAssignment(id) {
    let params = { id: id };
    return get("user/form/assignment", params);
  },
  getFormAssignments(params) {
    return get("user/form/assignment/search", params);
  },
  getFormOpen(id) {
    let params = { id: id };
    return get("user/form/open/", params);
  },
  getFormOpens(params) {
    return get("user/form/open/search", params);
  },
  getFormSubmission(params) {
    return get("user/form/submission", params);
  },
  getFormSubmissions(params) {
    return get("user/form/submission/search", params);
  },
  saveFormSubmission(params) {
    return post("user/form/submission", params);
  },
  updateFormSubmission(params) {
    return put("user/form/submission", params);
  },
  deleteFormSubmission(id) {
    let params = { id: id };
    return del("user/form/submission", params);
  },
  // removed
  // downloadAsset(params) {
  //   return post("/user/form/asset/download", params);
  // },
  getFormAsset(params) {
    return get("user/form/asset", params);
  },
  getFormAssetSearch(params) {
    return get("user/form/asset/search", params);
  },
  getFormAssetToken(params) {
    return post("user/form/asset/token", params);
  },
  deleteFormSubmissionAsset(params) {
    return del("user/form/submission/asset", params);
  },
  getFormSubmissionAsset(params) {
    return get("user/form/submission/asset", params);
  },
  createFormSubmissionAsset(params) {
    return post("user/form/submission/asset", params);
  },
  getFormSubmissionAssets(params) {
    return get("user/form/submission/asset/search", params);
  },
};

export { FormService };
