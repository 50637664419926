import { get } from "./api.service";

const InternalService = {
  versionGet() {
    return get("/public/util/version");
  },

  serverTimeGet() {
    return get("/public/util/servertime");
  },
};

export { InternalService };
