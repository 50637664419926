import { get, post } from "./api.service";

const MessageService = {
  getMessage(notificationTrackingId) {
    let params = { notificationTrackingId: notificationTrackingId };
    return post("/user/notification/read", params);
  },
  getMessages(params) {
    return get("/user/notification/headers/search", params);
  },
  getMessageLatestCompany() {
    return post("/user/notification/read/latest/company");
  },
  getMessageLatestSystem() {
    return post("/user/notification/read/latest/system");
  },
};

export { MessageService };
