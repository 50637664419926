import Vue from "vue";
import Vuetify from "@/plugins/vuetify";
import { UserService } from "@services";
import { Settings } from "luxon";

const state = {
  timeFormat24Hour: null,
  darkTheme: true, // dark mode by default
  lang: "en-US",
  version: null,
  timeZone: Settings.defaultZoneName,
  showDevFAB: false,
};

const getters = {
  settings: (state) => {
    Settings.defaultZoneName = state.timeZone;
    return state;
  },
};

const actions = {
  //key value works, but avoiding to kiss.
  async upsertSetting({ commit }, { key, value }) {
    commit("upsertSetting", { key, value });
  },
  async setLang({ commit }, lang) {
    commit("setLang", lang);
  },
  async toggleTimeFormat({ commit }) {
    commit("toggleTimeFormat");
  },
  async toggleDarkMode({ commit }) {
    commit("toggleDarkMode");
  },
  async getUser() {
    return await UserService.getUser();
  },
  async updateUserProfile({ dispatch }, params) {
    return await UserService.updateUserProfile(params).then((r) => {
      dispatch("user/updateUser", r, { root: true });
      return r;
    });
  },
  async setShowDevFAB({ commit }, show) {
    commit("setShowDevFAB", show);
  },
};

const mutations = {
  toggleTimeFormat(state) {
    state.timeFormat24Hour = !state.timeFormat24Hour;
  },
  toggleDarkMode(state) {
    if (state.darkTheme === null) {
      state.darkTheme = false;
    } else {
      state.darkTheme = !state.darkTheme;
    }
    Vuetify.framework.theme.dark = !Vuetify.framework.theme.dark;
  },
  setTimeZone(state, timeZone) {
    state.timeZone = timeZone;
    Settings.defaultZoneName = timeZone;
  },
  setLang(state, lang) {
    state.lang = lang;
  },
  setShowDevFAB(state, show) {
    state.showDevFAB = show;
  },
  upsertSetting(state, { key, value }) {
    Vue.set(state, key, value);
  },
  version(state, version) {
    state.version = version;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
