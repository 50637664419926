const state = {
  content: "",
  color: "",
  timeout: "",
  type: "",
};
const getters = {};
const mutations = {
  // modify state synchronously
  showMessage(state, payload) {
    state.content = payload.content;
    state.color = payload.color;
    state.timeout = payload.timeout ? payload.timeout : 5000;
    state.type = payload.type ? payload.type : "notification";
  },
  hideMessage(state) {
    state.timeout = 100;
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
