import { UserService, TokenService } from "@services";
import { removeAuthHeader } from "@services/api.service";
import idb from "@/api/idb";
import router from "@/router";
import tracer from "@/diagnostics/tracer";

const state = {
  accessToken: null,
  tokenAwait: false,
};

const getters = {
  isAuthenticated: (state) => {
    return state.accessToken ? true : false;
  },
};

const actions = {
  loadAccessToken() {
    state.accessToken = TokenService.getToken();
  },

  async login({ dispatch }, { email, password }) {
    tracer.info("[AUTH] Login Attempt | " + email);

    let r = await UserService.login(email, password);
    if (r == "ok") {
      await dispatch("user/setUser", null, { root: true });
    }
    return r;
  },

  async logout({ dispatch }) {
    tracer.info("[AUTH] Logout");
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    state.accessToken = "";
    removeAuthHeader();
    router.push({ path: "/login" });
    idb.deleteAllStores(); //deleting db is wonky... deleting everything in it works.
    dispatch("user/removeUser", null, { root: true });
    dispatch("timeClock/removeTimeClocks", null, { root: true });
    dispatch("location/removeGeo", null, { root: true });
    dispatch("document/removeDocuments", null, { root: true });
    dispatch("message/removeMessages", null, { root: true });
  },

  async restart({ dispatch }) {
    tracer.info("[AUTH] Restart");
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    state.accessToken = "";
    removeAuthHeader();
    idb.deleteAllStores();
    dispatch("user/removeUser", null, { root: true });
    dispatch("timeClock/removeTimeClocks", null, { root: true });
    dispatch("location/removeGeo", null, { root: true });
    dispatch("document/removeDocuments", null, { root: true });
    dispatch("message/removeMessages", null, { root: true });
    window.location.href = "/";
  },

  async registerUser(_, params) {
    return await UserService.registerUser(params);
  },

  async getInvitation(_, params) {
    return await UserService.getInvitation(params);
  },

  async resetPassword(_, params) {
    return await UserService.resetPassword(params);
  },

  async changePassword(_, params) {
    return await UserService.changePassword(params);
  },

  async forgotPassword(_, params) {
    return await UserService.forgotPassword(params);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
