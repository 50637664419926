import { TrainingService, AssetService } from "@services";

const state = {};

const getters = {};

const actions = {
  async getCourse(_, courseId) {
    return await TrainingService.getCourse(courseId);
  },
  async getCourses(_, params) {
    return await TrainingService.getCourses(params);
  },
  async getCourseAsset(_, courseId) {
    return await TrainingService.getCourseAsset(courseId);
  },
  async getCourseAssets(_, courseId) {
    return await TrainingService.getCourseAssets(courseId);
  },
  async getCourseCredit(_, courseId) {
    return await TrainingService.getCourseCredit(courseId);
  },
  async getCourseCredits(_, params) {
    return await TrainingService.getCourseCredits(params);
  },
  async getCourseCreditTopic(_, creditTopicId) {
    return await TrainingService.getCourseCreditTopic(creditTopicId);
  },
  async getCourseInstructor(_, courseId) {
    return await TrainingService.getCourseInstructor(courseId);
  },
  async createUserDownloadToken(_, params) {
    return await TrainingService.createUserDownloadToken(params);
  },
  async downloadAsset(_, params) {
    return await AssetService.downloadAsset(params);
  },
  async getAsset(_, params) {
    return await AssetService.getAsset(params);
  },
  async startEnrollment(_, courseId) {
    return await TrainingService.startEnrollment(courseId);
  },

  async cancelEnrollment(_, courseEnrollmentId) {
    return await TrainingService.cancelEnrollment(courseEnrollmentId);
  },

  async searchEnrollments(_, params) {
    return await TrainingService.searchEnrollments(params);
  },

  async getEnrollmentDetails(_, params) {
    const data = await TrainingService.searchEnrollments(params);
    const enrollments = await data.data;
    const enrollmentDetails = enrollments.filter((enrollment) => {
      return enrollment.courseId === params.courseId;
    });
    if (enrollmentDetails.length) {
      return enrollmentDetails[0];
    } else {
      return null;
    }
  },

  async searchWebinars(_, params) {
    return await TrainingService.searchWebinars(params);
  },

  async getWebinar(_, courseWebinarId) {
    return await TrainingService.getWebinar(courseWebinarId);
  },

  async searchWebinarRegistrations(_, params) {
    return await TrainingService.searchWebinarRegistrations(params);
  },

  async getRegistration(_, webinarRegistrationId) {
    return await TrainingService.searchWebinarRegistrations(webinarRegistrationId);
  },
  async createWebinarRegistration(_, params) {
    return await TrainingService.createWebinarRegistration(params);
  },

  async deleteRegistration(_, webinarRegistrationId) {
    return await TrainingService.deleteRegistration(webinarRegistrationId);
  },

  async genCertificate(_, courseEnrollmentId) {
    return await TrainingService.submitEnrollment(courseEnrollmentId);
  },
  async getAttempts(_, courseEnrollmentId) {
    return await TrainingService.getExamAttempts(courseEnrollmentId);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
