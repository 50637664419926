import { FormService } from "@services";
import Vue from "vue";

const state = {
  forms: null,
};

const getters = {
  forms: (state) => {
    return { ...state.forms };
  },
};

const actions = {
  async getFormAssignment(_, assignmentId) {
    return await FormService.getFormAssignment(assignmentId);
  },
  async getFormAssignments({ commit, state }, params) {
    if (!Vue.prototype.$online) {
      return state.forms;
    } else {
      let f = await FormService.getFormAssignments(params);
      commit("forms", f);
      return f;
    }
  },
  async getFormOpen(_, formId) {
    return await FormService.getFormOpen(formId);
  },
  async getFormOpens(_, formId) {
    return await FormService.getFormOpens(formId);
  },
  async getFormSubmission(_, params) {
    return await FormService.getFormSubmission(params);
  },
  async getFormSubmissions(_, params) {
    return await FormService.getFormSubmissions(params);
  },
  async getFormRevision(_, revisionId) {
    return await FormService.getFormRevision(revisionId);
  },
  async saveFormSubmission(_, params) {
    return await FormService.saveFormSubmission(params);
  },
  async updateFormSubmission(_, params) {
    return await FormService.updateFormSubmission(params);
  },
  async deleteFormSubmission(_, id) {
    return await FormService.deleteFormSubmission(id);
  },
};

const mutations = {
  forms(state, value) {
    state.forms = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
