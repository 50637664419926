import { get, del, post } from "./api.service";

const NotificationService = {
  getFCMToken(params) {
    return get("/user/session/push", params);
  },
  registerFCMToken(params) {
    return post("/user/session/push", params);
  },
  unregisterFCMToken(params) {
    return del("/user/session/push", params);
  },
};

export { NotificationService };
