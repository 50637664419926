import VueI18n from "vue-i18n";
import Vue from "vue";
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE, NUMBER_FORMATS } from "@/constants/trans";
import en from "@/lang/en-US.json";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  numberFormats: NUMBER_FORMATS,
  messages: { en }, // set locale messages
  silentFallbackWarn: true,
  //dateTimeFormats: DATETIME_FORMATS, //using luxon for datetime internationalization
});
