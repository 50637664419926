export default {
  install(Vue) {
    Vue.filter("phoneNumber", function (value) {
      return value.replace(/[^0-9]/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }),
      Vue.filter("firstChars", function (value, number) {
        return value.slice(0, number);
      });
    Vue.filter("truncate", function (data, num) {
      const s = data.split("").slice(0, num).join("");
      return s;
    });
  },
};
