import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import api from "./modules/api";
import auth from "./modules/auth";
import user from "./modules/user";
import timeClock from "./modules/timeclock";
import message from "./modules/message";
import document from "./modules/document";
import forms from "./modules/forms";
import issue from "./modules/issue";
import training from "./modules/training";
import settings from "./modules/settings";
import location from "./modules/location";
import ui from "./modules/ui";
import snackbar from "./modules/snackbar";
import loader from "./modules/loader";
import notifications from "./modules/notifications";

export const VUEX_KEY = "ranes";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: VUEX_KEY,
  storage: window.localStorage,
  reducer: (state) => ({
    settings: state.settings,
    user: state.user,
    message: state.message,
    document: state.document,
    forms: state.forms,
  }), //this determines what gets saved in localstorage
});

export default new Vuex.Store({
  modules: {
    api,
    auth,
    user,
    timeClock,
    message,
    document,
    forms,
    issue,
    training,
    location,
    settings,
    ui,
    snackbar,
    loader,
    notifications,
  },
  //strict: false,
  plugins: [vuexLocal.plugin],
});
