import { TokenService, UserService } from "@services";
import idb from "@/api/idb";
import jwt_decode from "jwt-decode";

const state = {
  user: null,
};

const getters = {
  user: (state) => {
    return { ...state.user };
  },
};

const actions = {
  setUser({ commit }) {
    const accessToken = TokenService.getToken();
    let user = jwt_decode(accessToken);
    commit("user", user);
    idb.saveUser(user);
  },
  async updateUser({ commit }, user) {
    return await UserService.getUser(user.id).then((r) => {
      let user = r.data;
      commit("user", user);
      idb.saveUser(user);
    });
  },
  removeUser({ commit }) {
    commit("user", null);
  },
};

const mutations = {
  user(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
