import { get, post, patch, del } from "./api.service";

const IssueService = {
  //Status Enum
  // { "o", "Open" },
  // { "a", "Assigned" },
  // { "p", "In Progress" },
  // { "h", "On Hold" },
  // { "r", "Resolved" },
  // { "c", "Closed" },
  // { "d", "Deleted" },

  getIssue(issueId) {
    let params = { id: issueId };
    return get("/user/issue", params);
  },

  getIssues(params) {
    return get("/user/issue/search", params);
  },

  addIssue(params) {
    return post("/user/issue", params);
  },

  patchIssue(params) {
    return patch("/user/issue", params);
  },

  // id, issueId, xcoord, ycoord, comment, severity, status, createdOn, updatedOn, tags, metaData
  getIssueComment(commentIssueId) {
    let params = { commentIssueId: commentIssueId };
    return get("/user/issue/comment", params);
  },

  getIssueComments(params) {
    return get("/user/issue/comment/search", params);
  },

  addIssueComment(params) {
    return post("/user/issue/comment", params);
  },

  patchIssueComment(params) {
    return patch("/user/issue/comment", params);
  },

  deleteIssueComment(id) {
    return del("/user/issue/comment", { id });
  },
};

export { IssueService };
